

export default function CardSomaPeso({ somaPesos }) {
    return(
        <div>
            <h2>
                Peso Total: <span>{somaPesos}</span> Kg
            </h2>
        </div>
    )
}