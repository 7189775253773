import NavBar from '../../Components/NavBar';
import CardsControlesProcesso from '../../Components/Cards/CardsControlesProcesso';

export default function ControleProcessos() {
    return (
        <>
            <NavBar />
            <CardsControlesProcesso/>
        </>
    )
}